@import '../shared/variables.scss';

.Page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    .Page_header {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        height: 60px;
        z-index: 3000;
        h2 {
            font-weight: 500;
            line-height: 60px;
            text-align: center;
            display: block;
            color: $color-white;
            font-size: 16px;
            font-family: 'Hind', serif;
        }
        .Page_back {
            position: absolute;
            height: 60px;
            line-height: 50px;
            top: 0;
            left: 25px;
            width: 18px;
            cursor: pointer;
            font-size: 20px;
            background-repeat: no-repeat;
            background-image: url('/images/back.svg');
            background-position: center center;
            background-size: contain;
        }
    }
    .Page_content {
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;
        .Page_transition {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color-white;
        }
    }
}
