@import '../shared/variables.scss';

.App {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .App_header {
        position: relative;
        height: 60px;
        background-color: $color-black;
        z-index: 2000;
    }
    .App_alert {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .25);
        z-index: 5000;
        .App_alert_window {
            position: relative;
            background-color: $color-white;
            width: 85%;
            padding: 25px;
            border-radius: 5px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, .05);
            border: 1px solid $color-grey-light;
            h3 {
                color: $color-grey-dark;
                font-weight: 600;
                line-height: 1.6em;
                padding-bottom: 5px;
            }
            p {
                color: $color-grey-medium;
                line-height: 1.6em;
                i {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    margin: 0 5px;
                    transform: scale(1.6);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    &.ios {
                        background-image: url('/images/ios.svg');
                    }
                    &.android {
                        background-image: url('/images/android.svg');
                    }
                }
            }
        }
    }
}