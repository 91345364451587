@import '../shared/variables.scss';

.Onboarding {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;
    background-color: $color-black;
    display: flex;
    flex-direction: column;
    .Onboarding_header {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        p {
            color: red;
            position: absolute;
            display: block;
            width: 100%;
            text-align: center;
            top: 50%;
            left: 0;
            font-weight: 500;
            line-height: 1.3em;
            color: $color-white;
            font-size: 17px;
            font-family: 'Hind', serif;
            &.fx-enter {
                opacity: 0;
                transform: translateY(-20px);
            }
            &.fx-enter.fx-enter-active {
                opacity: 1;
                transform: translateY(0);
                transition: all 500ms ease 500ms;
            }
            &.fx-exit {
                opacity: 1;
                transform: translateY(0);
            }
            &.fx-exit.fx-exit-active {
                opacity: 0;
                transform: translateY(20px);
                transition: all 500ms ease;
            }
        }
    }
    .Onboarding_content {
        position: relative;
        height: 70vh;
        width: 100%;
        transform: translateY(-2vh);
        .Onboarding_canvas {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            .Onboarding_layer {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .Onboarding_footer {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        button {
            color: $color-white;
            font-size: 14px;
            font-weight: 300;
            border: 1px solid $color-white;
            background-color: $color-black;
            border-radius: 4px;
            height: 50px;
            padding: 0 20px;
            min-width: 240px;
            cursor: pointer;
            transform: translateY(calc(2vh - 6px));
            &:active {
                background-color: #222222;
            }
        }
    }
    &.fx-enter {
        opacity: 0;
    }
    &.fx-enter.fx-enter-active {
        opacity: 1;
        transition: all 500ms ease;
    }
    &.fx-exit {
        opacity: 1;
    }
    &.fx-exit.fx-exit-active {
        opacity: 0;
        transition: all 500ms ease;
    }
}
