@import '../shared/variables.scss';

.Structure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    .Structure_credits {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: $color-grey-medium;
        height: 80px;
        p {
            text-align: center;
            font-weight: 600;
            line-height: 2em;
        }
        ul {
            display: flex;
            li {
                margin: 0 5px;
                cursor: pointer;
                line-height: 1.2em;
                border-bottom: 1px solid lighten($color-grey-medium, 20%);
            }
        }
    }
    .Structure_list {
        position: relative;
        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        pointer-events: none;
        .Structure_item {
            position: relative;
            height: 90px;
            border-bottom: 1px solid lighten($color-grey-light, 20%);
            padding: 0 60px 0 25px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            background-color: $color-white;
            pointer-events: all;
            &:active {
                background-color: lighten($color-background, 5%);
            }
            h3 {
                color: $color-grey-dark;
                font-weight: 600;
                line-height: 1.6em;
            }
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $color-grey-medium;
                line-height: 1.6em;
            }
            &.Structure_category:after {
                content: '';
                position: absolute;
                top: 0;
                right: 25px;
                bottom: 0;
                width: 6px;
                line-height: 90px;
                color: $color-grey-light;
                font-size: 20px;
                background-repeat: no-repeat;
                background-image: url('/images/arrow.svg');
                background-position: center center;
                pointer-events: none;
                background-size: contain;
            }
        }
    }
    &.Structure_start .Structure_list {
        padding-bottom: 80px;
    }
    .Structure_empty {
        position: relative;
        text-align: center;
        color: $color-grey-medium;
        padding: 50px 25px;
    }
}
