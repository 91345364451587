@import '../shared/variables.scss';

.Loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-image: url('/images/loader.svg');
    background-position: center center;
    pointer-events: none;
}
