@import '../shared/variables.scss';

.Disclaimer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    padding: 25px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    h2 {
        color: $color-black;
        font-weight: 600;
        margin-bottom: 10px;
    }
    p {
        line-height: 1.6em;
        color: $color-grey-dark;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            color: $color-black;
            text-decoration: underline;
        }
    }
}
