@import '../shared/variables.scss';

.Drag {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    @media #{$breakpoint-desktop} {
        display: none;
    }
    .Drag_backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        pointer-events: none;
        opacity: 0;
    }
    .Drag_card {
        position: absolute;
        width: 100vw;
        height: 200vh;
        background-color: $color-white;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 -5px 5px rgba(0, 0, 0, 0);
        top: calc(100vh - 100px);
        .Drag_card_content {
            position: relative;
            width: 100vw;
            height: calc(100vh - 160px);
            pointer-events: none;
        }
        .Drag_dragger {
            position: absolute;
            width: 100vw;
            height: 40px;
            border-top: 1px solid $color-white;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px 10px 0 0;
            transition: all 500ms ease;
            .Drag_dragger_fade {
                position: absolute;
                width: 100%;
                height: 40px;
                background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 1) 75%,
                    rgba(255, 255, 255, 0) 100%
                );
                top: 0;
                left: 0;
                border-radius: 10px 10px 0 0;
            }
            .Drag_dragger_handle {
                position: relative;
                display: block;
                width: 30px;
                height: 30px;
                cursor: pointer;
                transition: opacity 500ms ease;
                opacity: 0;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                background-image: url('/images/chevron.svg');
            }
        }
    }
    &.Drag_animate {
        .Drag_card,
        .Drag_backdrop {
            transition: all 500ms ease;
        }
    }
    &.Drag_open {
        .Drag_backdrop {
            pointer-events: all;
        }
        .Drag_card {
            transform: translateY(calc(-100vh + 160px));
            .Drag_card_content {
                pointer-events: all;
            }
            .Drag_dragger .Drag_dragger_handle {
                transform: rotate(180deg);
            }
        }
    }
    &.Drag_active {
        .Drag_card {
            box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.05);
            pointer-events: all;
            .Drag_dragger {
                border-top: 1px solid lighten($color-grey-light, 25%);
                .Drag_dragger_handle {
                    opacity: 1;
                }
            }
        }
    }
}
