@import '../shared/variables.scss';

.Article {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    @media #{$breakpoint-desktop} {
        flex-direction: row;
    }
    .Article_carousel {
        position: relative;
        width: 100vw;
        height: calc(100vw + 50px);
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        @media #{$breakpoint-desktop} {
            width: calc(100vh - 60px);
            height: calc(100vh - 60px);
            padding: 50px 50px 0 50px;
        }
        .Article_carousel_go_left {
            position: absolute;
            cursor: pointer;
            width: 25px;
            height: 100px;
            left: 20px;
            top: 50%;
            z-index: 900;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 10px auto;
            background-image: url('/images/chevron-left.svg');
            display: none;
            @media #{$breakpoint-desktop} {
                display: flex;
            }
        }
        .Article_carousel_go_right {
            position: absolute;
            cursor: pointer;
            width: 25px;
            height: 100px;
            left: calc(100vh - 105px);
            top: 50%;
            z-index: 900;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 10px auto;
            background-image: url('/images/chevron-right.svg');
            display: none;
            @media #{$breakpoint-desktop} {
                display: flex;
            }
        }
        .Article_slider {
            position: relative;
            width: 100vw;
            height: 100vw;
            background-color: #dddddd;
            flex-grow: 0;
            flex-shrink: 0;
            overflow: hidden;
            @media #{$breakpoint-desktop} {
                width: calc(100vh - 160px);
                height: calc(100vh - 160px);
            }
            .Article_slider_container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                .Article_slide {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    flex-grow: 0;
                    flex-shrink: 0;
                    overflow: hidden;
                    cursor: pointer;
                    .Article_zoom {
                        position: relative;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        .Article_image {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            pointer-events: none;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                        .Article_overlay {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            opacity: 0;
                            transition: all 500ms ease;
                            pointer-events: none;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                            .Article_point {
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                top: 0;
                                left: 0;
                                border-radius: 30px;
                                transform: translate(-50%, -50%);
                                transition: opacity 250ms ease;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 28px;
                                    height: 28px;
                                    background-color: $color-white;
                                    border: 1px solid darken($color-background, 5%);
                                    border-radius: 30px;
                                    cursor: pointer;
                                    pointer-events: all;
                                    transition: all 500ms ease;
                                    transform: scale(0.8);
                                    opacity: 0.5;
                                }
                                &.Article_point_active {
                                    z-index: 1000;
                                    &:after {
                                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
                                        transform: scale(1);
                                        background-color: $color-black;
                                        opacity: 0.75;
                                        z-index: 1000;
                                    }
                                }
                            }
                            &.Article_overlay_active {
                                opacity: 1;
                            }
                        }
                        &.Article_transition {
                            transition: transform 250ms ease;
                            .Article_overlay,
                            .Article_point {
                                transition: all 250ms ease;
                            }
                        }
                    }
                }
            }
            .Article_hint {
                position: absolute;
                left: 25px;
                bottom: 25px;
                background-color: lighten($color-grey-light, 25%);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                padding: 0;
                border-radius: 30px;
                width: 30px;
                pointer-events: none;
                transition: opacity 250ms ease;
                i {
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    background-image: url('/images/layer.svg');
                    width: 14px;
                    height: 14px;
                    transition: opacity 500ms ease;
                    opacity: 0.4;
                }
                p {
                    margin-left: 10px;
                    transform: translateY(-1px);
                    display: none;
                }
                @media #{$breakpoint-desktop} {
                    padding: 0 15px 0 10px;
                    width: auto;
                    p {
                        display: block;
                    }
                }
                &.Article_hint_active i {
                    opacity: 1;
                }
            }
            &.Article_animate .Article_slider_container {
                transition: all 500ms ease;
            }
            &.Article_scaling {
                .Article_hint {
                    opacity: 0;
                }
                .Article_point {
                    opacity: 0;
                }
            }
        }
        .Article_indicators {
            position: relative;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            ul {
                display: flex;
                li {
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: darken($color-background, 10%);
                    margin: 0 4px;
                    border-radius: 8px;
                    transition: all 500ms ease;
                    &.Article_indicator_active {
                        background-color: $color-grey-medium;
                    }
                }
            }
        }
    }
    .Article_information {
        position: relative;
        width: 100vw;
        flex-grow: 1;
        flex-shrink: 0;
        @media #{$breakpoint-desktop} {
            display: none;
        }
        .Article_information_holder {
            position: relative;
            padding: 0 25px;
            h3 {
                color: $color-grey-dark;
                font-weight: 600;
                line-height: 1.6em;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                em {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    background-color: $color-black;
                    border-radius: 15px;
                    opacity: 0.8;
                }
            }
            p {
                line-height: 1.6em;
                color: $color-grey-dark;
                margin-bottom: 10px;
            }
            h4 {
                line-height: 1.6em;
                color: $color-grey-dark;
                margin-bottom: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .Article_desktop {
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        display: none;
        padding: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 50px 50px 40px 50px;
        @media #{$breakpoint-desktop} {
            display: block;
        }
        .Article_desktop_holder {
            position: relative;
            button {
                position: relative;
                font-family: inherit;
                font-weight: 600;
                font-size: inherit;
                border: 0;
                border-radius: 5px;
                border: 1px solid $color-black;
                color: $color-black;
                height: 50px;
                display: block;
                padding: 0 20px;
                cursor: pointer;
                margin-top: 25px;
                background-color: $color-white;
                &.Acticle_button_active {
                    color: $color-white;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -45%);
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        background-image: url('/images/chevron-dark.svg');
                    }
                }
            }
            h3 {
                color: $color-grey-dark;
                font-weight: 600;
                line-height: 1.6em;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                em {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    background-color: $color-black;
                    border-radius: 15px;
                    opacity: 0.8;
                }
            }
            p {
                line-height: 1.6em;
                color: $color-grey-dark;
                margin-bottom: 10px;
            }
            h4 {
                line-height: 1.6em;
                color: $color-grey-dark;
                margin-bottom: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
            b,
            em {
                font-weight: bold;
            }
            i {
                font-style: italic;
            }
            u {
                text-decoration: underline;
            }
            ul {
                list-style: disc outside;
                margin-left: 1.2em;
                margin-bottom: 10px;
                li {
                    p {
                        margin: 0;
                    }
                }
            }
            ol {
                list-style: disc outside;
                margin-left: 1.2em;
                margin-bottom: 10px;
                li {
                    p {
                        margin: 0;
                    }
                }
            }
            .Article_desktop_extra {
                padding-top: 25px;
                margin-top: 25px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
    .Article_card {
        position: relative;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        .Article_card_holder {
            position: relative;
            padding: 40px 25px 15px 25px;
            h3 {
                color: $color-grey-dark;
                font-weight: 600;
                line-height: 1.6em;
                margin-bottom: 10px;
                text-align: center;
            }
            p {
                line-height: 1.6em;
                color: $color-grey-dark;
                margin-bottom: 10px;
            }
            h4 {
                line-height: 1.6em;
                color: $color-grey-dark;
                margin-bottom: 10px;
                text-decoration: underline;
                cursor: pointer;
            }
            b,
            em {
                font-weight: bold;
            }
            i {
                font-style: italic;
            }
            u {
                text-decoration: underline;
            }
            b,
            em {
                font-weight: bold;
            }
            i {
                font-style: italic;
            }
            u {
                text-decoration: underline;
            }
            ul {
                list-style: disc outside;
                margin-left: 1.2em;
                margin-bottom: 10px;
                li {
                    p {
                        margin: 0;
                    }
                }
            }
            ol {
                list-style: disc outside;
                margin-left: 1.2em;
                margin-bottom: 10px;
                li {
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}
