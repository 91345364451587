@import 'shared/reset.scss';
@import 'shared/fonts.scss';
@import 'shared/variables.scss';

* {
    touch-action: pan-x pan-y;
}
::-webkit-scrollbar {
    display: none;
}
html,
body {
    height: 100%;
    user-select: none;
    overflow: hidden;
}
body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    font-family: 'Open Sans', serif;
    font-weight: 300;
    font-size: 14px;
    color: $color-black;
    background-color: $color-white;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4em;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
