@import '../shared/variables.scss';

.Animator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &.PUSH .Page {
        &.fx-enter {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(50vw);
                opacity: 0;
            }
            .Page_transition {
                z-index: 1000;
                transform: translateX(100vw);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0);
            }
            .Page_back {
                opacity: 0;
            }
        }
        &.fx-enter.fx-enter-active {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(0);
                opacity: 1;
                transition: all 500ms ease;
            }
            .Page_transition {
                transform: translateX(0);
                transition: all 500ms ease;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            }
            .Page_back {
                opacity: 1;
                transition: all 500ms ease;
            }
        }
        &.fx-exit {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(0);
                opacity: 1;
            }
            .Page_transition {
                z-index: 999;
                transform: translateX(0);
            }
            .Page_back {
                opacity: 1;
            }
        }
        &.fx-exit.fx-exit-active {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(-50vw);
                opacity: 0;
                transition: all 500ms ease;
            }
            .Page_transition {
                transform: translateX(-25vw);
                transition: all 500ms ease;
            }
            .Page_back {
                opacity: 0;
                transition: all 500ms ease;
            }
        }
    }
    &.POP .Page {
        &.fx-enter {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(-50vw);
                opacity: 0;
            }
            .Page_transition {
                z-index: 999;
                transform: translateX(-25vw);
            }
            .Page_back {
                opacity: 0;
            }
        }
        &.fx-enter.fx-enter-active {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(0);
                opacity: 1;
                transition: all 500ms ease;
            }
            .Page_transition {
                transform: translateX(0);
                transition: all 500ms ease;
            }
            .Page_back {
                opacity: 1;
                transition: all 500ms ease;
            }
        }
        &.fx-exit {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(0);
                opacity: 1;
            }
            .Page_transition {
                z-index: 1000;
                transform: translateX(0);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            }
            .Page_back {
                opacity: 1;
            }
        }
        &.fx-exit.fx-exit-active {
            pointer-events: none;
            .Page_header h2 {
                transform: translateX(50vw);
                opacity: 0;
                transition: all 500ms ease;
            }
            .Page_transition {
                transform: translateX(100vw);
                transition: all 500ms ease;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0);
            }
            .Page_back {
                opacity: 0;
                transition: all 500ms ease;
            }
        }
    }
}
